import React, { useState, useEffect } from "react";

import { Multiselect } from "multiselect-react-dropdown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import Cookies from "universal-cookie";

import * as ProviderService from "../../services/ProviderService";
import { IMAGE } from "../../Constants/Image";
import swal from "sweetalert";

function LoginSection({ setForgetSection, setLoginSection, setChangeSection }) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [passwordAlert, setPasswordAlert] = useState("");
  const [password, setPassword] = useState("");
  const [society, setSociety] = useState(null);
  const [societyAlert, setSocietyAlert] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [filtro_soc] = useState([
    { type: "(1010) Colombina S.A", id: "1010" },
    { type: "(1020) Colombina del Cauca S.A", id: "1020" },
    { type: "(1030) Distrib. Colombina Ltda", id: "1030" },
    { type: "(1040) Prod. Andina DULCES S.A", id: "1040" },
    { type: "(1050) CONSERVAS COLOMBNA S.A", id: "1050" },
    { type: "(1130) Chicles Colombina S.A", id: "1130" },
    { type: "(1140) Prod. Lácteos Robin Hood", id: "1140" },
    { type: "(1150) Ind. Nal Alimentos Lácteos", id: "1150" },
    { type: "(1170) COMEXA DE COLOMBINA S.A", id: "1170" },
    { type: "(1175) COLOMBINA ENERGIA S.A.S", id: "1175" },
    { type: "(1180) FUERZA LOGISTICA DE DISTR", id: "1180" },
    { type: "(1210) Fundación Colombina", id: "1210" },
    { type: "(3020) Procalidad S.A.Costa Rica", id: "3020" },
    { type: "(3030) Procalidad de El Salvador", id: "3030" },
    { type: "(3050) CAPSA S.A", id: "3050" },
    { type: "(3060) Negocios CentroAme S.A HN", id: "3060" },
    { type: "(3070) Negocios CentroAme S.A PA", id: "3070" },
    { type: "(4050) Colombina Candy Inc", id: "4050" },
    { type: "(7030) Pierrot España SL", id: "7030" },
  ]);

  const cookies = new Cookies();

  const handlerClick = async (e) => {
    /**
     * React - Preventing Form Submission
     * In javascript you can prevent that by using an event handler and
     * calling e.preventDefault() on button click, or form submit. e is
     * the event object passed into the event handler.
     */
    e.preventDefault();
    if (!validations()) {
      return;
    }
    setShowLoader(true);

    let data = {
      Usuario: email,
      Clave: password,
      Sociedad: society,
    };
    ProviderService.validaAcceso(data)
      .then((response) => {
        if (response.data.name === "Error") {
          if (response.data.message === "Request failed with status code 500") {
            swal({
              title: "Error",
              text: "Servicio en mantenimiento",
              icon: "warning",
            });
          } else {
            swal({
              title: "Error",
              text: "Error al iniciar sesión",
              icon: "warning",
            });
          }
          setShowLoader(false);
        } else {
          var data = response.data["n0:PortalProveedoresDataResponse"];
          if (data) {
            if (data.validaAcceso) {
              if (data.validaAcceso.E_CSAP && !data.validaAcceso.E_MENS) {
                cookies.set("user_portal", email, { path: "/" });
                cookies.set("society", society, { path: "/" });
                cookies.set("e_csap", data.validaAcceso.E_CSAP, {
                  path: "/",
                });
                setShowLoader(false);
                navigate("/Inicio");
              } else {
                swal({
                  title: "Error de inicio de sesión",
                  text: data.validaAcceso.E_MENS,
                  icon: "error",
                  button: "Aceptar",
                });
                setShowLoader(false);
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (email !== "") {
      validationEmail();
    }
  }, [email]);

  useEffect(() => {
    if (password !== "") {
      validationPassword();
    }
  }, [password]);

  useEffect(() => {
    if (society !== null) {
      validationSociety();
    }
  }, [society]);

  /**
   * Global Validations
   */
  const validations = () => {
    if (password && password.length < 6 && !email) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      setPasswordAlert("¡La contraseña debe tener mínimo 6 caracteres!");
      return false;
    } else if (!email && !password && !society) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      setPasswordAlert("¡Debes escribir una contraseña para iniciar sesión!");
      setSocietyAlert("¡Debes escribir una sociedad para iniciar sesión!");
      return false;
    } else if (!email && !password) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      setPasswordAlert("¡Debes escribir una contraseña para iniciar sesión!");
      return false;
    } else if (!email) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      return false;
    } else if (!password) {
      setPasswordAlert("¡Debes escribir una contraseña para iniciar sesión!");
      return false;
    } else if (password.length < 6) {
      setPasswordAlert("¡La contraseña debe tener mínimo 6 caracteres!");
      return false;
    } else if (!society) {
      setSocietyAlert("¡Debes escribir una sociedad para iniciar sesión!");
      return false;
    }
    return true;
  };

  /**
   * Email Validations
   */
  const validationEmail = () => {
    if (!email) {
      setEmailAlert("¡Debes escribir un usuario para iniciar sesión!");
      return;
    } else {
      setEmailAlert("");
      return;
    }
  };

  /**
   * Society Validations
   */
  const validationSociety = () => {
    if (!society) {
      setSocietyAlert("¡Debes escribir una sociedad para iniciar sesión!");
      return false;
    } else {
      setSocietyAlert("");
      return true;
    }
  };

  /**
   * Password Validations
   */
  const validationPassword = () => {
    if (!password) {
      setPasswordAlert("¡Debes escribir una contraseña para iniciar sesión!");
      return;
    } else if (password.length < 6) {
      setPasswordAlert("¡La contraseña debe tener mínimo 6 caracteres!");
      return;
    } else {
      setPasswordAlert("");
      return;
    }
  };

  function onSelectType(selectedList, selectedItem) {
    const type = selectedList.map((item) => item);
    setSociety(type[0].id);
  }

  return (
    <div>
      <div className="flex flex-col justify-center md:justify-start my-auto pt-4 md:pt-0 px-4 md:px-24 lg:px-32 mb-10">
        <img
          src={IMAGE.COLOMBINA_IMAGE}
          alt="imagen producto"
          className="w-1/3"
        />
        <p className="text-center text-3xl font-Flama-Regular font-bold mt-4">
          Bienvenido
        </p>
        <form className="flex flex-col p-2" onSubmit={handlerClick}>
          <div className="flex flex-col pt-4">
            <label htmlFor="email" className="text-lg font-Flama-Regular">
              Usuario
            </label>
            <input
              id="email"
              placeholder="Usuario"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
            {emailAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{emailAlert}</p>
              </div>
            )}
          </div>

          <div className="flex flex-col pt-4">
            <label htmlFor="password" className="text-lg font-Flama-Regular">
              Contraseña
            </label>
            <div className="flex flex-row">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Contraseña"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
              <div
                className="rounded-full h-10 w-10 flex items-center justify-center"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityIcon></VisibilityIcon>
                ) : (
                  <VisibilityOffIcon></VisibilityOffIcon>
                )}
              </div>
            </div>
            {passwordAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{passwordAlert}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col pt-4">
            <label htmlFor="society" className="text-lg font-Flama-Regular">
              Sociedad
            </label>
            <Multiselect
              options={filtro_soc}
              singleSelect={true}
              displayValue="type"
              placeholder="Sociedades"
              emptyRecordMsg="No hay sociedades"
              avoidHighlightFirstOption={true}
              onSelect={onSelectType}
              id={3}
            />
            {societyAlert !== "" && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 mt-2"
                role="alert"
              >
                <p className="font-bold">Error:</p>
                <p>{societyAlert}</p>
              </div>
            )}
          </div>
          <button
            className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8 font-Flama-Regular"
            type="submit"
          >
            Iniciar sesión
          </button>
        </form>
        {showLoader ? (
          <div className="flex items-center justify-center flex-col mt-6">
            <RingLoader color="blue" size={50} />
          </div>
        ) : null}
        <div className="flex flex-col items-center p-2">
          <div className="text-center mt-1">
            <a
              href="https://portal-proveedores-col.s3.amazonaws.com/Instructivo.pdf"
              target="_blank"
            >
              <label className="mb-20 text-blue-500 cursor-pointer text-sm">
                Ver instructivo
              </label>
            </a>
          </div>
          <div className="text-center mt-1">
            <a
              href="https://portal-proveedores-col.s3.amazonaws.com/Detallado_proveedores.pdf"
              target="_blank"
            >
              <label className="mb-20 text-blue-500 cursor-pointer text-sm">
                Ver instructivo detallado proveedores
              </label>
            </a>
          </div>
          <div className="text-center mt-1">
            <a
              href="https://portal-proveedores-col.s3.amazonaws.com/Detallado_inversionistas.pdf"
              target="_blank"
            >
              <label className="mb-20 text-blue-500 cursor-pointer text-sm">
                Ver instructivo detallado inversionistas
              </label>
            </a>
          </div>
          <div className="text-center pt-1 pb-12">
            <button
              className="bg-blue-400  font-bold text-lg hover:bg-blue-600 p-2 mt-8 font-Flama-Regular mx-1"
              onClick={() => {
                setForgetSection(true);
                setLoginSection(false);
              }}
            >
              Recordar Clave
            </button>
            <button
              className="bg-blue-400  font-bold text-lg hover:bg-blue-600 p-2 mt-8 font-Flama-Regular mx-1"
              onClick={() => {
                setLoginSection(false);
                setChangeSection(true);
              }}
            >
              Cambiar Clave
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSection;
